<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
	<div class="px-10">
		<div>


			<a-row :gutter="24" class="px-15" style="margin-top: 0px;">
				<a-col :span="24" :md="24" :lg="24" :xl="24" class="">
					<h5>Notifications</h5>
				</a-col>

				<a-col :span="24" :md="24" :lg="24" :xl="24" class="mb-24">
					<a-badge 
						v-for="option in filterItems"
						:key="option.uuid"
						style="font-size: 14px !important; margin: 2px 3px; padding: 5px;" 
						:count="`${option.name}    ${option.icon}`" 
						@click="onSelectFilterItem(option.uuid)"
						:number-style="pagination.filter == option.uuid ? 
							{backgroundColor: '#734EBF !important', color: '#FFFFFF', paddingTop: '5px', paddingRight: '18px', paddingLeft: '18px', height: '32px', fontSize: '14px'} : 
							{border: '1px solid #734EBF !important', color: '#734EBF', backgroundColor: 'transparent !important', paddingTop: '5px', paddingRight: '18px', paddingLeft: '18px', height: '32px', fontSize: '14px'}"/>
					
				</a-col>

				<a-col :span="24" :md="24" :lg="24" :xl="24" class="mb-24 text-right">
					<a-pagination type="success" class="btn btn-silabu" v-model="currentPage" :total="pagination.total" :pageSize="pagination.perPage" show-less-items />
				</a-col>
			</a-row>
			
			<div v-if="!loadingData">

				<a-row :gutter="24" class="mt-20">

					<a-col class="mb-24" style="height: 100%;" :span="24" :md="24" :lg="24" :xl="24" v-for="(group, notificationDate) in notifications" :key="notificationDate">

						<a-row :gutter="24">

							<a-col :span="24">
								<h6 class="mb-20">{{ notificationDate == todayDate ? 'Today' : notificationDate }}</h6>
							</a-col>

							<a-col class="mb-24" style="height: 100%;" :span="24" :xs="24" :sm="24" :md="24" :lg="12" :xl="6" v-for="notif in group" :key="notif.uuid">
								<a-card class=" px-5 h-100" @click="handleNotificationClicked(notif)">

									<a-row :gutter="24">
										<a-col :span="24" :md="24" :lg="24" :xl="24">
											<a-row :gutter="24">
												<a-col :span="6" :sm="24" :md="24" :lg="6" :xl="6" class="text-center">

													<a-avatar :size="50" class="mt-5" v-if="notif.creator != null" :src="notif.creator.profile != null ? notif.creator.profile : ''" alt="Han Solo">{{ notif.creator.profile != null ? '' : `${notif.creator.firstName[0]}${notif.creator.lastName[0]}` }}</a-avatar>
													<a-avatar :size="50" class="mt-5" v-if="notif.creator == null">
														<template #icon>
															<font-awesome-icon icon="fa-regular fa-bell" class="ml-auto mr-auto mt-auto mb-auto" style=""/>
														</template>
													</a-avatar>
												</a-col>

												<a-col :span="18" :sm="24" :md="24" :lg="18" :xl="18">
													
													<a-row :gutter="24" class="mt-5">
														<a-col :span="24" :md="24" :lg="24" :xl="24">
															<h6 class="mb-0 pb-0" style="font-weight: 600; font-size: 16px;">{{ notif.title }}</h6>
														<!-- </a-col>

														<a-col :span="24" :md="4" :lg="4" :xl="4" class="text-right"> -->
															<!-- <label style="font-size: 12px;">{{ `${$Moment(notif.createdAt).format('hh:mm A')}` }} </label> -->
														</a-col>							
													</a-row>

													<a-row :gutter="24">
														<a-col :span="24" :md="24" :lg="24" :xl="24">
															<p>{{ notif.content }}</p>
														</a-col>
													</a-row>

													<a-row :gutter="24">
														<a-col :span="14" :md="14" :lg="14" :xl="12">
															<small>{{ `${formatName(notif.creator.firstName)} ${formatName(notif.creator.lastName)}` }} </small>
														</a-col>
														<a-col :span="10" :md="10" :lg="10" :xl="12" class="text-right">
															<label style="font-size: 12px;">{{ `${$Moment(notif.createdAt).format('hh:mm A')}` }} </label>
														</a-col>
													</a-row>

													<a-row :gutter="24">
														<a-col :span="24" :md="24" :lg="24" :xl="24">
															<!-- <a-badge  
																style="font-size: 14px !important;" 
																:count="`📖 ${notif.chapters != null ? notif.chapters : '0'} Chapters`" 
																:number-style="{backgroundColor: '#F1F1F1 !important', color: '#444444', paddingTop: '4px', height: '30px'}"/> &nbsp;

															<a-badge  
																style="font-size: 14px !important;" 
																:count="`🧩 2 Quizzes`" 
																:number-style="{backgroundColor: '#F1F1F1 !important', color: '#444444', paddingTop: '4px', height: '30px'}"/> -->
														</a-col>
													</a-row>

													
												</a-col>

											</a-row>
											
										</a-col>
									</a-row>
									
								</a-card>
							</a-col>
							
						</a-row>
					</a-col>

				</a-row>


			</div>


			<div v-if="loadingData">

				<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 60vh">

					<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

						<a-spin class="text-primary" size="large"/>

					</a-col>

				</a-row>
			</div>

			<div v-if="!loadingData && Object.keys(notificationList).length === 0">

				<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="px-20" style="min-height: 50vh">

					<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

						<img src="../../../public/images/no_data.png" alt="" width="70%" style="" class=""> 

						<h6 class="mt-5" style="font-weight: 600; color: #8C8C8C">No notifications found</h6>
						
					</a-col>

				</a-row>
			</div>

		</div>


	</div>
</template>

<script>
    import { notification } from 'ant-design-vue';

	export default ({
		
		components: {
		},

		data() {
			return {
				loadingData: false,
				message: '',
				currentPage: 1,
                pagination: {
					search: '',
                    filter: 'today',
                    perPage: 10,
                    currentPage: 1,
                    totalPages: 1,
                    perPageOptions: [10, 20, 30, 50],
                    total: 0
                },
                notifications: [],
				notificationList: [],
				form: this.$form.createForm(this, { name: 'topic_login' }),
				isRepeatable: false,

				filterItems: [
                    { uuid: 'today', name: 'Today ', icon: ' '},
                    { uuid: 'yesterday', name: 'Yesterday ', icon: ' '},
                    { uuid: 'week', name: 'This Week ', icon: ' '},
                    { uuid: 'month', name: 'This Month ', icon: ' '},
                ],

				repeatitions: [
					{ uuid: 1, name: 'Daily' },
					{ uuid: 7, name: 'Weekly' }, 
					{ uuid: 30, name: 'Monthly' },
					{ uuid: 365, name: 'Yearly' },
				],

				userDetails: {},

				todayDate: this.$Moment(new Date).format('DD MMM YYYY') 
      		}
		},
		mounted () {
			let breadcrumbs = [
				{ title: 'Notifications', url: '/notifications', isActive: true }
			];
			
			this.$root.$refs.Breadcrumbs.initializeBreadCrumbs(breadcrumbs)
		},
		created() {
			this.getNotifications();
			// this.getUserDetails();
		},
		watch: {
			currentPage: {
                handler() {
					this.pagination.currentPage = this.currentPage;
					this.getNotifications()
                },
            },
            deep: true,
        },
		methods: {

			formatName(name) {
				let formattedName = name.length > 30 ? `${name[0].toUpperCase()}${name.substring(1, 25).toLowerCase()}...` : `${name[0].toUpperCase()}${name.substring(1).toLowerCase()}`;
                return formattedName;
            },

			filterByExpiration (notifications) {
				let today = new Date();
				let formattedDate = this.$Moment(today.toISOString()).format('DD-MM-YYYY');
				
				return notifications.filter((item) => this.$Moment(item.startDate) >= this.$Moment())
			},


			handleNotificationClicked(notification) {

				if(notification.title.toLowerCase().includes('new message')) {
					let classUuid = notification.data.clazz;

					this.$router.push(`/my-classes/view/${classUuid}`)
				}

			},


			onSelectFilterItem(item) {
				this.pagination.filter = item;

				this.pagination.currentPage = 1;

				this.getNotifications()
			},



			async getNotifications() {

				this.loadingData = true;

                const { currentPage, perPage, filter } = this.pagination;

				let url

				url = `${this.$BACKEND_URL}/notifications/mine?orderBy=createdAt&order=ASC&populate=true&page=${currentPage}&size=${perPage}`;
				// if(filter == 'today') {
				// 	let today = this.$Moment().format('YYYY-MM-DD')
				// 	console.log(this.formatDateToUTC(today))
				// 	url = `${this.$BACKEND_URL}/notifications/mine?createdAt=${today}&orderBy=createdAt&order=ASC&populate=true&page=${currentPage}&size=${perPage}`;
				// }

				// if(filter == 'yesterday') {
				// 	let yesterday = this.$Moment().format('YYYY-MM-DD')
				// 	url = `${this.$BACKEND_URL}/notifications/mine?createdAt=${yesterday}&orderBy=createdAt&order=ASC&populate=true&page=${currentPage}&size=${perPage}`;
				// }

				// if(filter == 'week') {
				// 	let week = this.$Moment().format('YYYY-MM-DD')
				// 	url = `${this.$BACKEND_URL}/notifications/mine?createdAt=${week}&orderBy=createdAt&order=ASC&populate=true&page=${currentPage}&size=${perPage}`;
				// }

				// if(filter == 'month') {
				// 	let month = this.$Moment().format('YYYY-MM-DD')
				// 	url = `${this.$BACKEND_URL}/notifications/mine?createdAt=${month}&orderBy=createdAt&order=ASC&populate=true&page=${currentPage}&size=${perPage}`;
				// }

                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status == 200) {
						
						// console.log(this.filterByExpiration(response.data.data))
						this.notificationList = response.data.data;
						this.pagination.totalPages = response.data.pagination != null ? response.data.pagination.totalPages : 1;
                        this.pagination.total = response.data.pagination != null ? response.data.pagination.totalItems : 0;
                        this.pagination.currentPage = response.data.pagination != null ? response.data.pagination.currentPage : 1;
						this.currentPage = response.data.pagination != null ? response.data.pagination.currentPage : 1;

						this.notifications = await this.filterByExpiration(response.data.data).reduce((notification, currentNotification) => {
							
							const category = this.$Moment(currentNotification.createdAt).format('DD MMM YYYY');

							if (!notification[category]) {
								notification[category] = [];
							}
							notification[category].push(currentNotification);
							return notification;
						}, {});

						this.readNotifications();
						
                    }
					
					this.loadingData = false;
					
                }).catch(async(error) => {
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },


			formatDateToUTC(date, time) {
				return new Date(
					this.$Moment(date).format('YYYY'), 
					this.$Moment(date).format('MM') - 1, 
					this.$Moment(date).format('DD'), 
					this.$Moment(time).format('HH'),
					this.$Moment(time).format('mm'),
					this.$Moment(time).format('ss'),
					0,
				).toISOString();
			},


			readNotifications() {

				let url = `${this.$BACKEND_URL}/notifications/read`;
				
				this.$AXIOS.post(url, {}).then((response) => {
					if (response.status >= 200 && response.status < 210) {

					}
				}).catch((err) => {
					
				});

			},


			notify(message, type) {
                notification[type]({
                    message: message,
                    description: '',
                    placement: 'topRight',
                    duration: 3,
                });
                
            },

		}
	})

</script>

<style scoped>

.double-bounce1[data-v-33aee302], .double-bounce2[data-v-33aee302] {
	background-color: #734EBF !important;
	height: 70px;
	width: 70px;
}

a:hover {
	color: inherit !important;
}

#btn, #btn:hover {
	background-color: #734EBF !important;
	color: white !important;
}

.ant-switch-checked {
    background-color: #734EBF !important;
}

#startingTime {
    padding-bottom: 0%;
	margin-bottom: 0%;
	height: 17px;
}

#deleteBtn, #deleteBtn:hover { 
	background-color: inherit !important;
	border: none;
	color: #F5222D;
}

#editBtn, #editBtn:hover { 
	background-color: inherit !important;
	border: none;
	color: #734EBF;
}

/* .ant-tabs-nav:hover, .ant-tabs-tab-active {
	color: #734EBF !important;
	border-bottom: 1px solid#734EBF;
} */
</style>